@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Quicksand:wght@300;600&family=Raleway:wght@300;600&family=Ubuntu:wght@300;500&display=swap');
@import "../node_modules/react-image-gallery/styles/scss/image-gallery.scss";


$raleway: 'Raleway', sans-serif;
$ubuntu: 'Ubuntu', sans-serif;
$quicksand: 'Quicksand', sans-serif;
$blackopsone: 'Black Ops One', cursive;


$primary-color: #0280a4;
$secondary-color: #fff;


