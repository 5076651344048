@import "./_config.scss";
@import "./_buttons.scss";
@import "./_classes.scss";
@import "./components/01-Nav/_nav.scss";
@import "./components/02-Introduction/_introduction.scss";
@import "./components/03-Skills/_skills.scss";
@import "./components/04-Experience/_experience.scss";
@import "./components/04-Experience/_weatherApp.scss";
@import "./components/04-Experience/_strength.scss";
@import "./components/05-Workplaces/_workplaces.scss";
@import "./components/06-Studies/_studies.scss";
@import "./components/06-Studies/_hobbies.scss";
@import "./components/06-Studies/_filming.scss";
@import "./components/07-Conract/_contact.scss";



body {
  box-sizing: border-box;
}



