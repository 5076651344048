@keyframes scroll {
    0% {
    transform: translateX(0);
    }
    100% {
    transform: translateX(-46.3%);
    }
}

.marquee {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    width: 98%;
    background-color: #0280a4;
    padding: 8px 50px;
    border-radius: 25px; 
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); 
}

.marquee-content {
    padding: 0 70px;
    display: flex;
    animation: scroll 45s linear infinite;
}

.marquee-content > div {
    display: inline-block;
    padding: 0 20px;
    font-size: 3rem;
    color: white;
}
