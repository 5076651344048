.contact-button {
  position: relative;
  background: #0280a4;
  color: #fff;
  padding: 0.35em 0;
  font-size: 17px;
  border: none;
  border-radius: 0.7em;
  letter-spacing: 0.08em;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;
  height: 2.7em;
  padding-left: 3em;
  padding-right: 1.5em;
}

.contact-button .icon {
  position: absolute;
  background: #fff;
  margin-right: 1em;
  height: 2.2em;
  width: 2.2em;
  left: 0.2em;
  transition: all .7s;
  gap: 10px;
  border-radius: 0.7em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shere {
  color: #0280a4;
  width: 1.3rem;
  height: 1.3rem;
}

.contact-button:hover .icon {
  width: calc(100% - 0.6rem);
}

.contact-button:hover .shere {
  width: calc(100% - 0.6rem);
  display: none;
}

.contact-button .icon-shere {
  color: #0280a4;
  width: 1.5rem;
  height: 1.5rem;
  display: none;
}

.contact-button:hover .icon .icon-shere {
  display: flex;
}

.btn-outline-info {
    border-color: #0280a4 !important;
    color: #0280a4 !important;
}
.btn-outline-info:hover,
.btn-outline-info:active,
.btn-outline-info:visited,
.btn-outline-info:focus {
  background-color: #0280a4 !important;
  border-color: #0280a4 !important;
  color: #fff !important;
}

.buttons {
  display: flex;
  justify-content: space-around;
  top: 20px;
  left: 20px;
}

.buttons button {
  width: 150px;
  height: 50px;
  background-color: white;
  margin: 20px;
  color: #568fa6;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.buttons button:before, .buttons button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #44d8a4;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button:before {
  right: 0;
  top: 0;
  transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button:after {
  left: 0;
  bottom: 0;
}

.buttons button span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.buttons button span:before, .buttons button span:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 0;
  background-color: #44d8a4;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button span:before {
  right: 0;
  top: 0;
  transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button span:after {
  left: 0;
  bottom: 0;
}

.buttons button p {
  padding: 0;
  margin: 0;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  position: absolute;
  width: 100%;
  height: 100%;
}

.buttons button p:before, .buttons button p:after {
  position: absolute;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  z-index: 1;
  left: 0;
}

.buttons button p:before {
  content: attr(data-title);
  top: 50%;
  transform: translateY(-50%);
}

.buttons button p:after {
  content: attr(data-text);
  top: 150%;
  color: #44c2d8;
}

.buttons button:hover:before, .buttons button:hover:after {
  width: 100%;
}

.buttons button:hover span {
  z-index: 1;
}

.buttons button:hover span:before, .buttons button:hover span:after {
  height: 100%;
}

.buttons button:hover p:before {
  top: -50%;
  transform: rotate(5deg);
}

.buttons button:hover p:after {
  top: 50%;
  transform: translateY(-50%);
}

.buttons button.start {
  background-color: #44d8a4;
  box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.buttons button.start p:before {
  top: -50%;
  transform: rotate(5deg);
}

.buttons button.start p:after {
  color: white;
  transition: all 0s ease;
  content: attr(data-start);
  top: 50%;
  transform: translateY(-50%);
  animation: start 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes start {
  from {
    top: -50%;
  }
}

.buttons button.start:hover:before, .buttons button.start:hover:after {
  display: none;
}

.buttons button.start:hover span {
  display: none;
}

.buttons button:active {
  outline: none;
  border: none;
}

.buttons button:focus {
  outline: 0;
}


///

.btn2 {
  width: 200px;
  height: 50px;
  border-radius: 5px;
  border: none;
  transition: all 0.5s ease-in-out;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  background: #06D6A0;
  color: #f5f5f5;
  -webkit-box-shadow: 8px 7px 12px 5px rgba(0,0,0,0.6); 
box-shadow: 8px 7px 12px 5px rgba(0,0,0,0.6);
}

.btn2:hover {
  box-shadow: 0 0 20px 0px #2e2e2e3a;
}

.btn2 .icon {
  position: absolute;
  height: 40px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.btn2 .text {
  transform: translateX(55px);
}

.btn2:hover .icon {
  width: 175px;
}

.btn2:hover .text {
  transition: all 0.5s;
  opacity: 0;
}

.btn2:focus {
  outline: none;
}

.btn2:active .icon {
  transform: scale(0.85);
}