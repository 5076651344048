.App {
    background-image: url('../src/images/silver.jpg');
    background-attachment: fixed;
    background-size: cover;
}
a:link, a:visited {
  font-family: $quicksand;
    color: #0280a4;
    text-align: justify;
}
a:link:active, a:visited:active, a:link:hover, a:visited:hover {
  color: #58c8e7;
}
.container-fluid {
    padding: 0;
    z-index: 8 !important;
}
.container {
    justify-content: right;
}
.main {
  z-index: 7 !important;
} 

.firstBackground {
  background-color: $secondary-color;
  font-family: $raleway;
  font-weight: 600;
  color: $primary-color;
  text-align: justify;
  
}
.secondBackground {
  background-color: $primary-color;
  font-family: $raleway;
  font-weight: 600;
  color: $secondary-color;
  z-index: -3;
}

.hover-zoom {
  height: auto; 
  overflow: hidden; 
}
.hover-zoom img {
  transition: transform .5s ease;
}
.hover-zoom:hover img {
  transform: scale(1.3);
}
.parallelogram {
  background-color: #C4D7F2 ;
  font-family: $ubuntu;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  transform: skew(-20deg);
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
  max-width: 600px;
  max-height: 80px;
}
@media (max-width: 992px) {
  .parallelogram {
    width: 400px;
  }
}

.border-shadow {
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  border: 0px solid #000000;
  -webkit-box-shadow: 3px 6px 26px 2px rgba(0,0,0,0.65);
  -moz-box-shadow: 3px 6px 26px 2px rgba(0,0,0,0.65);
  box-shadow: 3px 6px 26px 2px rgba(0,0,0,0.65);
}


.logoImage {
  width: 4rem;
}
.fadeIn {
    animation: 0.5s fadeIn forwards;
  }
  
  .fadeOut {
    animation: 0.5s fadeOut forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-20px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translate(0px, 0px);
    }
    to {
      transform: translate(-20px, 0);
      opacity: 0;
    }
  }




