
.introduction {
    border-top: 1px solid $primary-color;
    background-image: url(../src/images/introductionBack.webp);
    background-size: cover;
    background-position-x: left;
}
@media (max-width: 992px) {
    .introduction {
        background-image: none;
        border-top: none;
        padding: 2rem;
    }
}

.btn-outline-warning {
    color: #0280a4 !important;
}