// Nav styles

.navbar {
    z-index: 2;
}
.navbar-title {
    font-size: 1,3rem;
}
@media (max-width: 992px) {
    .navbar-title {
        font-size: 0,5rem;
        text-align: left;
    }
}
.navbar-nav li:hover {
    background-color: #7fabb8;

}
.navbar-nav li a:hover {
    color:$secondary-color;
}
.main-nav {
    font-family: $raleway;
    background-color: #ffffff;
    color: #2c2b2b;
    -webkit-box-shadow: 3px 6px 26px 2px rgba(0,0,0,0.65);
    -moz-box-shadow: 3px 6px 26px 2px rgba(0,0,0,0.65);
    box-shadow: 3px 6px 26px 2px rgba(0,0,0,0.65);
}
.nav-link.active{
    font-weight: 600;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid black;
}
.navbar-brand {
    cursor: auto;
}