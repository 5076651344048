@import url("https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Quicksand:wght@300;600&family=Raleway:wght@300;600&family=Ubuntu:wght@300;500&display=swap");
.image-gallery-icon {
  color: #fff;
  transition: all 0.3s ease-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}
.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 28px;
  width: 28px;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: 0.6;
  pointer-events: none;
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}
.image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}

.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 110px);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    width: calc(100% - 87px);
  }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.center {
  position: relative;
}
.image-gallery-slide .image-gallery-image {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: 0.8em;
    padding: 8px 15px;
  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 2px #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: all 0.2s ease-out;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet:focus {
  transform: scale(1.2);
  background: #337ab7;
  border: 1px solid #337ab7;
}
.image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(1.2);
  border: 1px solid #fff;
  background: #fff;
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    background: #337ab7;
    border: 1px solid #337ab7;
  }
  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #337ab7;
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  touch-action: pan-y;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  touch-action: pan-x;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}
.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: block;
  position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #337ab7;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    outline: none;
    border: 4px solid #337ab7;
  }
}
@media (hover: hover) and (pointer: fine) and (max-width: 768px) {
  .image-gallery-thumbnail:hover {
    border: 3px solid #337ab7;
  }
}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 0 2px 2px #1a1a1a;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: 0.8em;
    line-height: 0.8em;
  }
}

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: 0.8em;
    padding: 5px 10px;
  }
}

.contact-button {
  position: relative;
  background: #0280a4;
  color: #fff;
  padding: 0.35em 0;
  font-size: 17px;
  border: none;
  border-radius: 0.7em;
  letter-spacing: 0.08em;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;
  height: 2.7em;
  padding-left: 3em;
  padding-right: 1.5em;
}

.contact-button .icon {
  position: absolute;
  background: #fff;
  margin-right: 1em;
  height: 2.2em;
  width: 2.2em;
  left: 0.2em;
  transition: all 0.7s;
  gap: 10px;
  border-radius: 0.7em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shere {
  color: #0280a4;
  width: 1.3rem;
  height: 1.3rem;
}

.contact-button:hover .icon {
  width: calc(100% - 0.6rem);
}

.contact-button:hover .shere {
  width: calc(100% - 0.6rem);
  display: none;
}

.contact-button .icon-shere {
  color: #0280a4;
  width: 1.5rem;
  height: 1.5rem;
  display: none;
}

.contact-button:hover .icon .icon-shere {
  display: flex;
}

.btn-outline-info {
  border-color: #0280a4 !important;
  color: #0280a4 !important;
}

.btn-outline-info:hover,
.btn-outline-info:active,
.btn-outline-info:visited,
.btn-outline-info:focus {
  background-color: #0280a4 !important;
  border-color: #0280a4 !important;
  color: #fff !important;
}

.buttons {
  display: flex;
  justify-content: space-around;
  top: 20px;
  left: 20px;
}

.buttons button {
  width: 150px;
  height: 50px;
  background-color: white;
  margin: 20px;
  color: #568fa6;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.buttons button:before, .buttons button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #44d8a4;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button:before {
  right: 0;
  top: 0;
  transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button:after {
  left: 0;
  bottom: 0;
}

.buttons button span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.buttons button span:before, .buttons button span:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 0;
  background-color: #44d8a4;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button span:before {
  right: 0;
  top: 0;
  transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button span:after {
  left: 0;
  bottom: 0;
}

.buttons button p {
  padding: 0;
  margin: 0;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  position: absolute;
  width: 100%;
  height: 100%;
}

.buttons button p:before, .buttons button p:after {
  position: absolute;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  z-index: 1;
  left: 0;
}

.buttons button p:before {
  content: attr(data-title);
  top: 50%;
  transform: translateY(-50%);
}

.buttons button p:after {
  content: attr(data-text);
  top: 150%;
  color: #44c2d8;
}

.buttons button:hover:before, .buttons button:hover:after {
  width: 100%;
}

.buttons button:hover span {
  z-index: 1;
}

.buttons button:hover span:before, .buttons button:hover span:after {
  height: 100%;
}

.buttons button:hover p:before {
  top: -50%;
  transform: rotate(5deg);
}

.buttons button:hover p:after {
  top: 50%;
  transform: translateY(-50%);
}

.buttons button.start {
  background-color: #44d8a4;
  box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.buttons button.start p:before {
  top: -50%;
  transform: rotate(5deg);
}

.buttons button.start p:after {
  color: white;
  transition: all 0s ease;
  content: attr(data-start);
  top: 50%;
  transform: translateY(-50%);
  animation: start 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes start {
  from {
    top: -50%;
  }
}
.buttons button.start:hover:before, .buttons button.start:hover:after {
  display: none;
}

.buttons button.start:hover span {
  display: none;
}

.buttons button:active {
  outline: none;
  border: none;
}

.buttons button:focus {
  outline: 0;
}

.btn2 {
  width: 200px;
  height: 50px;
  border-radius: 5px;
  border: none;
  transition: all 0.5s ease-in-out;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  background: #06D6A0;
  color: #f5f5f5;
  box-shadow: 8px 7px 12px 5px rgba(0, 0, 0, 0.6);
}

.btn2:hover {
  box-shadow: 0 0 20px 0px rgba(46, 46, 46, 0.2274509804);
}

.btn2 .icon {
  position: absolute;
  height: 40px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.btn2 .text {
  transform: translateX(55px);
}

.btn2:hover .icon {
  width: 175px;
}

.btn2:hover .text {
  transition: all 0.5s;
  opacity: 0;
}

.btn2:focus {
  outline: none;
}

.btn2:active .icon {
  transform: scale(0.85);
}

.App {
  background-image: url("../src/images/silver.jpg");
  background-attachment: fixed;
  background-size: cover;
}

a:link, a:visited {
  font-family: "Quicksand", sans-serif;
  color: #0280a4;
  text-align: justify;
}

a:link:active, a:visited:active, a:link:hover, a:visited:hover {
  color: #58c8e7;
}

.container-fluid {
  padding: 0;
  z-index: 8 !important;
}

.container {
  justify-content: right;
}

.main {
  z-index: 7 !important;
}

.firstBackground {
  background-color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: #0280a4;
  text-align: justify;
}

.secondBackground {
  background-color: #0280a4;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: #fff;
  z-index: -3;
}

.hover-zoom {
  height: auto;
  overflow: hidden;
}

.hover-zoom img {
  transition: transform 0.5s ease;
}

.hover-zoom:hover img {
  transform: scale(1.3);
}

.parallelogram {
  background-color: #C4D7F2;
  font-family: "Ubuntu", sans-serif;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  transform: skew(-20deg);
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
  max-width: 600px;
  max-height: 80px;
}

@media (max-width: 992px) {
  .parallelogram {
    width: 400px;
  }
}
.border-shadow {
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  border: 0px solid #000000;
  box-shadow: 3px 6px 26px 2px rgba(0, 0, 0, 0.65);
}

.logoImage {
  width: 4rem;
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
.navbar {
  z-index: 2;
}

.navbar-title {
  font-size: 1, 3rem;
}

@media (max-width: 992px) {
  .navbar-title {
    font-size: 0, 5rem;
    text-align: left;
  }
}
.navbar-nav li:hover {
  background-color: #7fabb8;
}

.navbar-nav li a:hover {
  color: #fff;
}

.main-nav {
  font-family: "Raleway", sans-serif;
  background-color: #ffffff;
  color: #2c2b2b;
  box-shadow: 3px 6px 26px 2px rgba(0, 0, 0, 0.65);
}

.nav-link.active {
  font-weight: 600;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
}

.navbar-brand {
  cursor: auto;
}

.introduction {
  border-top: 1px solid #0280a4;
  background-image: url(../src/images/introductionBack.jpg);
  background-size: cover;
  background-position-x: left;
}

@media (max-width: 992px) {
  .introduction {
    background-image: none;
    border-top: none;
    padding: 2rem;
  }
}
.skillSum {
  background-color: #0280a4;
  max-width: 95%;
  position: static;
  margin: 0 auto 0 auto;
  border: 2px solid #fff;
  border-radius: 50px;
  box-shadow: 3px 6px 26px 2px rgba(0, 0, 0, 0.65);
}

.skillSum .row {
  list-style: none;
  font-size: 3rem;
  padding-left: 0;
  list-style-position: outside;
  color: #fff;
}

.btn-outline-warning {
  color: #0280a4 !important;
}

.skills-title {
  font-size: 3.8rem;
  color: #50C9E7;
  text-shadow: 2px 2px rgb(21, 19, 53);
  transform: skew(1deg);
}

.skills {
  background-image: url(./images/skills.jpg);
  background-repeat: no-repeat;
  background-attachment: cover;
  background-position: center bottom;
}

.skills-diamond1 {
  font-size: 8rem;
  animation: changeDiamond1 5s infinite alternate;
}

@keyframes changeDiamond1 {
  from {
    color: #607196;
  }
  to {
    color: #aad6e2;
    text-shadow: 0 0 20px black;
  }
}
.skills-diamond2 {
  font-size: 8rem;
  animation: changeDiamond2 5s infinite alternate;
}

@keyframes changeDiamond2 {
  from {
    color: #BABFD1;
    text-shadow: 0 0 20px black;
  }
  to {
    color: #546A7B;
  }
}
.skills-diamond3 {
  font-size: 8rem;
  animation: changeDiamond3 5s infinite alternate;
}

@keyframes changeDiamond3 {
  from {
    color: #679ceb;
  }
  to {
    color: #c7c9cc;
    text-shadow: 0 0 20px black;
  }
}
.skills-diamond4 {
  font-size: 8rem;
  animation: changeDiamond4 5s infinite alternate;
}

@keyframes changeDiamond4 {
  from {
    color: #aad6e2;
    text-shadow: 0 0 20px black;
  }
  to {
    color: #607196;
  }
}
.webdesigner {
  color: #607196;
  font-weight: 600;
  text-decoration: underline;
}

.frontend {
  color: #BABFD1;
  font-weight: 600;
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.backend {
  color: #80A1D4;
  font-weight: 600;
  padding-left: 600px;
  text-decoration: underline;
}

@media (max-width: 1400px) {
  .skills {
    background-image: url(./images/skills2.jpg);
  }
}
@media (max-width: 1200px) {
  .skills {
    background-image: url(./images/skills3.jpg);
    background-position: left bottom;
  }
  .backend {
    padding-left: 450px;
  }
}
@media (max-width: 992px) {
  .skills {
    background-image: none;
  }
  .frontend {
    display: block;
    margin-left: 40px;
  }
  .backend { 
    padding-left: 50px;
    
  }
}
.experience-title {
  font-size: 3.2rem;
  color: #50C9E7;
  text-shadow: 2px 2px rgb(21, 19, 53);
}

.rel {
  position: relative;
  z-index: 1;
}

.abs {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.weather {
  display: flex;
  flex-direction: column;
  justify-content: flex;
  padding: 25px;
  background-repeat: no-repeat;
  background-attachment: cover;
  text-shadow: 2px 2px rgb(21, 19, 53);
  color: #fff;
}

.weather-input {
  padding: 15px;
  width: 50%;
  margin: auto;
  border: ipx solid lightgrey;
  border-radius: 6px;
  font-size: 16px;
}

.strength-background {
  background-image: url("./images/strength-background2.jpeg");
  background-size: cover;
  max-height: 100%;
  color: #fff;
}

.strength-h2 {
  margin: 5rem 8rem 5rem 0;
  background-color: rgba(247, 247, 247, 0.35);
  font-family: "Ubuntu", sans-serif;
  font-size: 3.8rem;
  color: #50C9E7;
  text-shadow: 2px 2px rgb(21, 19, 53);
}

.diamond {
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-bottom-color: #0280a4;
  position: relative;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.diamond:after {
  content: "";
  position: absolute;
  left: -50px;
  top: 50px;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-top-color: #0280a4;
}

.strength-content {
  position: relative;
  background-color: #fff;
  color: #0280a4;
  top: -100px;
  z-index: 0;
  min-height: 400px;
  padding-top: 4rem;
}

.workplace {
  position: relative;
  z-index: 0;
  right: 4rem;
  max-height: 900px;
  margin-top: -5rem;
  box-shadow: 0px 3px 11px 8px rgba(0, 0, 0, 0.75);
}

.workplace-image {
  z-index: 1;
}

.workplace-title {
  font-size: 3.8rem;
  margin-top: -11rem;
  color: #50C9E7;
  text-shadow: 2px 2px rgb(21, 19, 53);
}

@media (max-width: 992px) {
  .workplace {
    right: 0;
  }
  .workplace-title {
    font-size: 3.2rem;
  }
}
@media (max-width: 768px) {
  .workplace {
    max-height: 1200px;
    margin-top: -6rem;
  }
  .workplace-title {
    font-size: 2.8rem;
  }
}
.studies-title {
  font-size: 3.2rem;
  color: #50C9E7;
  text-shadow: 2px 2px rgb(21, 19, 53);
}

@media (max-width: 992px) {
  .studies-title {
    font-size: 2.5rem;
  }
}
.hobbies-h2 {
  margin: 5rem 0 5rem 0;
  padding-left: 14rem;
  background-color: rgba(247, 247, 247, 0.35);
  font-family: "Ubuntu", sans-serif;
  font-size: 3.8rem;
  color: #50C9E7;
  text-shadow: 2px 2px rgb(21, 19, 53);
}

.hobbies-background {
  background: rgb(12, 158, 243);
  background: linear-gradient(90deg, rgb(12, 158, 243) 0%, rgb(192, 192, 192) 50%, rgb(12, 158, 243) 100%);
}

.filmingSecondBackground {
  background-color: #0c9ef3;
  color: #fff;
}

.contact-background {
  background-image: url(./images/contact-back.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.contact-border {
  border: 2px solid #0280a4;
  box-shadow: 8px 7px 12px 5px rgba(0, 0, 0, 0.6);
}

.email-title {
  color: #0280a4;
}

@media (max-width: 992px) {
  .email-title {
    color: #fff;
  }
}
body {
  box-sizing: border-box;
}/*# sourceMappingURL=index.css.map */
.emailSender {
  position: relative;
}

.contactman {
  position: absolute;
  bottom: 0px;
  width: 300px;
  right: -80px;
  z-index: 2 !important;
}

form {
  position: relative;
  z-index: 1;
}
img {
  height: 100%;
  width: 100%;
}
.parallax-image-container {
  width: 1536;
  height: 1229;
}
