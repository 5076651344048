// Workplaces styles

.workplace {
    position: relative;
    z-index: 0;
    right: 4rem;
    max-height: 900px;
    margin-top: -5rem;
    -webkit-box-shadow: 0px 3px 11px 8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 11px 8px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 11px 8px rgba(0,0,0,0.75);
}
.workplace-image {
    z-index: 1;
}
.workplace-title {
    font-size: 3.8rem;
    margin-top: -11rem;
    color: #50C9E7;
    text-shadow: 2px 2px rgb(21, 19, 53);
}
@media (max-width: 992px) {
    .workplace {
        right: 0;
    }
    .workplace-title {
        font-size: 3.2rem;
    }
}
@media (max-width: 768px) {
    .workplace {
        max-height: 1200px;
        margin-top: -6rem;
    }
    .workplace-title {
        font-size: 2.4rem;
    }
}